/**  Do not modify manually! Generated by graphgen on 2024-12-14T00:49:41.957Z. */

import { Edges, RelFrom, RelTo, type SerializedGraph, Vertices } from "@plangs/graphgen/library";

/** Import user defined classes and types. */
import * as VC from ".";
import { PlangsVertex } from "./vertex_base";
import type * as VD from "./vertex_data_schemas";

/** Used for better autocomplete. */
export type TPlangsClasses = {
  app: VC.VApp;
  bundle: VC.VBundle;
  community: VC.VCommunity;
  learning: VC.VLearning;
  library: VC.VLibrary;
  license: VC.VLicense;
  paradigm: VC.VParadigm;
  plang: VC.VPlang;
  platform: VC.VPlatform;
  post: VC.VPost;
  subsystem: VC.VSubsystem;
  tag: VC.VTag;
  tool: VC.VTool;
  typeSystem: VC.VTypeSystem;
};

/** Every Vertex Name. */
export type TPlangsVertexName = keyof TPlangsClasses;

/** Every Generated Vertex Class. */
export type TPlangsVertex = TPlangsClasses[TPlangsVertexName];

/** Supported relations of each class. */
export type TPlangsRelations = {
  app: VAppRelName;
  bundle: VBundleRelName;
  community: VCommunityRelName;
  learning: VLearningRelName;
  library: VLibraryRelName;
  license: VLicenseRelName;
  paradigm: VParadigmRelName;
  plang: VPlangRelName;
  platform: VPlatformRelName;
  post: VPostRelName;
  subsystem: VSubsystemRelName;
  tag: VTagRelName;
  tool: VToolRelName;
  typeSystem: VTypeSystemRelName;
};

/** Supported edges of the graph. */
export type TPlangsEdgeName = keyof PlangsGraphBase["edges"];

/** Base class for the Plangs graph, generated from its specification. */
export class PlangsGraphBase {
  /** Return a type checked object identifying a relationship of a specific kind of Vertex. */
  static relConfig<T extends TPlangsVertexName>(vertexName: T, vertexRel: TPlangsRelations[T]) {
    // biome-ignore lint/style/noNonNullAssertion: correct by construction.
    const klass = PlangsGraphBase.vertexClass(vertexName)!;
    // @ts-ignore correct by construction.
    const { edgeName, direction }: { edgeName: TPlangsEdgeName; direction: "direct" | "inverse" } = klass.relConfig[vertexRel];
    return { kind: "rel", edgeName, direction } as const;
  }

  /** Return a type checked object identifying a property of the class that is "readable" (a prop returning a String, Boolean or Nunber). */
  static propConfig<T extends TPlangsVertexName>(vertexName: T, vertexProp: keyof TPlangsClasses[T]) {
    return { kind: "prop", vertexName, vertexProp } as const;
  }

  /** Get a Vertex class by its Vertex name. */
  static vertexClass<T extends TPlangsVertexName>(vertexName: T) {
    // We cannot use a Map here because each of the classes will not be initialized yet.
    if (vertexName === "app") return VC.VApp;
    if (vertexName === "bundle") return VC.VBundle;
    if (vertexName === "community") return VC.VCommunity;
    if (vertexName === "learning") return VC.VLearning;
    if (vertexName === "library") return VC.VLibrary;
    if (vertexName === "license") return VC.VLicense;
    if (vertexName === "paradigm") return VC.VParadigm;
    if (vertexName === "plang") return VC.VPlang;
    if (vertexName === "platform") return VC.VPlatform;
    if (vertexName === "post") return VC.VPost;
    if (vertexName === "subsystem") return VC.VSubsystem;
    if (vertexName === "tag") return VC.VTag;
    if (vertexName === "tool") return VC.VTool;
    if (vertexName === "typeSystem") return VC.VTypeSystem;
  }

  /** Vertex kinds per Vertex Name. The kind is the prefix of a Vertex Key. */
  static readonly vertexKind = new Map<TPlangsVertexName, string>([
    ["app", "app"],
    ["bundle", "bun"],
    ["community", "comm"],
    ["learning", "learn"],
    ["library", "lib"],
    ["license", "lic"],
    ["paradigm", "para"],
    ["plang", "pl"],
    ["platform", "plat"],
    ["post", "post"],
    ["subsystem", "sys"],
    ["tag", "tag"],
    ["tool", "tool"],
    ["typeSystem", "tsys"],
  ]);

  /** Reverse map of vertexKind. */
  static readonly vertexNameByKind = new Map<string, TPlangsVertexName>(Array.from(PlangsGraphBase.vertexKind.entries()).map(([k, v]) => [v, k]));

  /** Get a vertex by key, if the kind of vertex is known. */
  getVertex(vertexKey: string): TPlangsVertex | undefined {
    const kind = vertexKey.split("+", 2)[0] as TPlangsVertexName;
    const vertexName = PlangsGraphBase.vertexNameByKind.get(kind);
    if (!vertexName) return;
    return this.vertices[vertexName]?.get(vertexKey as any);
  }

  // Create a Vertices instances for each vertex.

  readonly app = new Vertices<VC.VApp>("app", "app", key => new VC.VApp(this, key));
  readonly bundle = new Vertices<VC.VBundle>("bundle", "bun", key => new VC.VBundle(this, key));
  readonly community = new Vertices<VC.VCommunity>("community", "comm", key => new VC.VCommunity(this, key));
  readonly learning = new Vertices<VC.VLearning>("learning", "learn", key => new VC.VLearning(this, key));
  readonly library = new Vertices<VC.VLibrary>("library", "lib", key => new VC.VLibrary(this, key));
  readonly license = new Vertices<VC.VLicense>("license", "lic", key => new VC.VLicense(this, key));
  readonly paradigm = new Vertices<VC.VParadigm>("paradigm", "para", key => new VC.VParadigm(this, key));
  readonly plang = new Vertices<VC.VPlang>("plang", "pl", key => new VC.VPlang(this, key));
  readonly platform = new Vertices<VC.VPlatform>("platform", "plat", key => new VC.VPlatform(this, key));
  readonly post = new Vertices<VC.VPost>("post", "post", key => new VC.VPost(this, key));
  readonly subsystem = new Vertices<VC.VSubsystem>("subsystem", "sys", key => new VC.VSubsystem(this, key));
  readonly tag = new Vertices<VC.VTag>("tag", "tag", key => new VC.VTag(this, key));
  readonly tool = new Vertices<VC.VTool>("tool", "tool", key => new VC.VTool(this, key));
  readonly typeSystem = new Vertices<VC.VTypeSystem>("typeSystem", "tsys", key => new VC.VTypeSystem(this, key));

  /** All vertex collections. */
  readonly vertices = {
    app: this.app,
    bundle: this.bundle,
    community: this.community,
    learning: this.learning,
    library: this.library,
    license: this.license,
    paradigm: this.paradigm,
    plang: this.plang,
    platform: this.platform,
    post: this.post,
    subsystem: this.subsystem,
    tag: this.tag,
    tool: this.tool,
    typeSystem: this.typeSystem,
  } as const;

  /** All edge collections. */
  readonly edges = {
    appRelPlatforms: new Edges(this.app, this.platform, "Platforms", "Apps"),
    appRelWrittenWith: new Edges(this.app, this.plang, "Plangs", "Apps"),
    bundleRelPlangs: new Edges(this.bundle, this.plang, "Plangs", "Bundles"),
    bundleRelTools: new Edges(this.bundle, this.tool, "Tools", "Bundles"),
    communityRelApps: new Edges(this.community, this.app, "Apps", "Communities"),
    communityRelLibraries: new Edges(this.community, this.library, "Libraries", "Communities"),
    communityRelPlangs: new Edges(this.community, this.plang, "Plangs", "Communities"),
    communityRelSubsystems: new Edges(this.community, this.subsystem, "Subsystems", "Communities"),
    communityRelTools: new Edges(this.community, this.tool, "Tools", "Communities"),
    learningRelApps: new Edges(this.learning, this.app, "Apps", "Learning Resources"),
    learningRelCommunities: new Edges(this.learning, this.community, "Communities", "Learning Resources"),
    learningRelLibraries: new Edges(this.learning, this.library, "Libraries", "Learning Resources"),
    learningRelPlangs: new Edges(this.learning, this.plang, "Plangs", "Learning Resources"),
    learningRelSubsystems: new Edges(this.learning, this.subsystem, "Subsystems", "Learning Resources"),
    learningRelTools: new Edges(this.learning, this.tool, "Tools", "Learning Resources"),
    libraryRelPlangs: new Edges(this.library, this.plang, "Plangs", "Libraries"),
    libraryRelPlatforms: new Edges(this.library, this.platform, "Platforms", "Libraries"),
    libraryRelWrittenWith: new Edges(this.library, this.plang, "Written With", "Used for Libraries"),
    licenseRelApps: new Edges(this.license, this.app, "Apps", "Licenses"),
    licenseRelLibraries: new Edges(this.license, this.library, "Libraries", "Licenses"),
    licenseRelPlangs: new Edges(this.license, this.plang, "Plangs", "Licenses"),
    licenseRelSubsystems: new Edges(this.license, this.subsystem, "Subsystems", "Licenses"),
    licenseRelTools: new Edges(this.license, this.tool, "Tools", "Licenses"),
    plangRelCompilesTo: new Edges(this.plang, this.plang, "Transpiling Targets", "Source Plangs"),
    plangRelDialectOf: new Edges(this.plang, this.plang, "Dialect of", "Dialects"),
    plangRelImplements: new Edges(this.plang, this.plang, "Implements", "Implemented By"),
    plangRelInfluencedBy: new Edges(this.plang, this.plang, "Influenced By", "Influenced"),
    plangRelParadigms: new Edges(this.plang, this.paradigm, "Paradigms", "Plangs"),
    plangRelPlatforms: new Edges(this.plang, this.platform, "Platforms", "Plangs"),
    plangRelTools: new Edges(this.plang, this.tool, "Tools", "Plangs"),
    plangRelTypeSystems: new Edges(this.plang, this.typeSystem, "Type Systems", "Plangs"),
    plangRelWrittenWith: new Edges(this.plang, this.plang, "Written With", "Used to Write"),
    postRelApps: new Edges(this.post, this.app, "Apps", "Posts"),
    postRelCommunities: new Edges(this.post, this.community, "Communities", "Posts"),
    postRelLearning: new Edges(this.post, this.learning, "Learning Resources", "Posts"),
    postRelLibraries: new Edges(this.post, this.library, "Libraries", "Posts"),
    postRelPlangs: new Edges(this.post, this.plang, "Plangs", "Posts"),
    postRelSubsystems: new Edges(this.post, this.subsystem, "Subsystems", "Posts"),
    postRelTools: new Edges(this.post, this.tool, "Tools", "Posts"),
    subsystemRelPlatforms: new Edges(this.subsystem, this.platform, "Platforms", "Subsystems"),
    subsystemRelWrittenWith: new Edges(this.subsystem, this.plang, "Plangs", "Subsystems"),
    tagRelApps: new Edges(this.tag, this.app, "Apps tagged", "Tags"),
    tagRelCommunities: new Edges(this.tag, this.community, "Communities", "Tags"),
    tagRelLearning: new Edges(this.tag, this.learning, "Learning Resources", "Tags"),
    tagRelLibraries: new Edges(this.tag, this.library, "Libraries", "Tags"),
    tagRelPlangs: new Edges(this.tag, this.plang, "Plangs", "Tags"),
    tagRelSubsystems: new Edges(this.tag, this.subsystem, "Subsystems", "Tags"),
    tagRelTools: new Edges(this.tag, this.tool, "Tools", "Tags"),
    toolRelPlatforms: new Edges(this.tool, this.platform, "Platforms", "Tools"),
    toolRelWrittenWith: new Edges(this.tool, this.plang, "Implemented With", "Tool Plang"),
  } as const;

  toJSON(): SerializedGraph {
    return {
      vertices: Object.fromEntries(Object.entries(this.vertices).map(([k, v]) => [k, v.toJSON()])),
      edges: Object.fromEntries(Object.entries(this.edges).map(([k, e]) => [k, e.toJSON()])),
    };
  }

  loadJSON(data: SerializedGraph): this {
    for (const [vertexName, vertices] of Object.entries(data.vertices)) {
      const map = this.vertices[vertexName as TPlangsVertexName];
      for (const [vertexKey, vertexData] of Object.entries(vertices)) {
        map.set(vertexKey as any, vertexData as any);
      }
    }
    for (const [edgeName, edges] of Object.entries(data.edges)) {
      const map = this.edges[edgeName as keyof typeof this.edges];
      for (const [fromKey, toKeys] of Object.entries(edges)) {
        map.add(fromKey as any, ...(toKeys as any[]));
      }
    }
    return this;
  }
}

/** Type of the key of an instance of {@link VApp}. */
export type VAppKey = `app+${string}`;

export type VAppRelName = keyof (typeof VAppBase)["relConfig"];

/** Software Application */
export abstract class VAppBase extends PlangsVertex<"app", VD.VAppData> {
  static readonly vertexKind = "app" as const;
  static readonly vertexName = "app" as const;
  static readonly vertexDesc = "Software Application";
  static readonly relConfig = {
    relCommunities: { edgeName: "communityRelApps", direction: "inverse", gen: false },
    relLearning: { edgeName: "learningRelApps", direction: "inverse", gen: false },
    relLicenses: { edgeName: "licenseRelApps", direction: "inverse", gen: true },
    relPlatforms: { edgeName: "appRelPlatforms", direction: "direct", gen: true },
    relPosts: { edgeName: "postRelApps", direction: "inverse", gen: false },
    relTags: { edgeName: "tagRelApps", direction: "inverse", gen: true },
    relWrittenWith: { edgeName: "appRelWrittenWith", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VAppBase.vertexKind;
  override readonly vertexDesc = VAppBase.vertexDesc;
  override readonly vertexName = VAppBase.vertexName;
  readonly relConfig = VAppBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VAppBase.relConfig) as VAppRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Communities `VCommunity-[communityRelApps]->(this:VApp)`. Inverse: {@link VCommunity.relApps}. */
  get relCommunities() {
    return new RelTo(this as unknown as VC.VApp, this.graph.edges.communityRelApps);
  }

  /** Learning Resources `VLearning-[learningRelApps]->(this:VApp)`. Inverse: {@link VLearning.relApps}. */
  get relLearning() {
    return new RelTo(this as unknown as VC.VApp, this.graph.edges.learningRelApps);
  }

  /** Licenses `VLicense-[licenseRelApps]->(this:VApp)`. Inverse: {@link VLicense.relApps}. */
  get relLicenses() {
    return new RelTo(this as unknown as VC.VApp, this.graph.edges.licenseRelApps);
  }

  /** Platforms `(this:VApp)-[appRelPlatforms]->VPlatform`. Inverse: {@link VPlatform.relApps}. */
  get relPlatforms() {
    return new RelFrom(this as unknown as VC.VApp, this.graph.edges.appRelPlatforms);
  }

  /** Posts `VPost-[postRelApps]->(this:VApp)`. Inverse: {@link VPost.relApps}. */
  get relPosts() {
    return new RelTo(this as unknown as VC.VApp, this.graph.edges.postRelApps);
  }

  /** Tags `VTag-[tagRelApps]->(this:VApp)`. Inverse: {@link VTag.relApps}. */
  get relTags() {
    return new RelTo(this as unknown as VC.VApp, this.graph.edges.tagRelApps);
  }

  /** Plangs `(this:VApp)-[appRelWrittenWith]->VPlang`. Inverse: {@link VPlang.relApps}. */
  get relWrittenWith() {
    return new RelFrom(this as unknown as VC.VApp, this.graph.edges.appRelWrittenWith);
  }
}

/** Type of the key of an instance of {@link VBundle}. */
export type VBundleKey = `bun+${string}`;

export type VBundleRelName = keyof (typeof VBundleBase)["relConfig"];

/** Bundle of Tools */
export abstract class VBundleBase extends PlangsVertex<"bun", VD.VBundleData> {
  static readonly vertexKind = "bun" as const;
  static readonly vertexName = "bundle" as const;
  static readonly vertexDesc = "Bundle of Tools";
  static readonly relConfig = {
    relPlangs: { edgeName: "bundleRelPlangs", direction: "direct", gen: false },
    relTools: { edgeName: "bundleRelTools", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VBundleBase.vertexKind;
  override readonly vertexDesc = VBundleBase.vertexDesc;
  override readonly vertexName = VBundleBase.vertexName;
  readonly relConfig = VBundleBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VBundleBase.relConfig) as VBundleRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Plangs `(this:VBundle)-[bundleRelPlangs]->VPlang`. Inverse: {@link VPlang.relBundles}. */
  get relPlangs() {
    return new RelFrom(this as unknown as VC.VBundle, this.graph.edges.bundleRelPlangs);
  }

  /** Tools `(this:VBundle)-[bundleRelTools]->VTool`. Inverse: {@link VTool.relBundles}. */
  get relTools() {
    return new RelFrom(this as unknown as VC.VBundle, this.graph.edges.bundleRelTools);
  }
}

/** Type of the key of an instance of {@link VCommunity}. */
export type VCommunityKey = `comm+${string}`;

export type VCommunityRelName = keyof (typeof VCommunityBase)["relConfig"];

/** Community */
export abstract class VCommunityBase extends PlangsVertex<"comm", VD.VCommunityData> {
  static readonly vertexKind = "comm" as const;
  static readonly vertexName = "community" as const;
  static readonly vertexDesc = "Community";
  static readonly relConfig = {
    relApps: { edgeName: "communityRelApps", direction: "direct", gen: true },
    relLearning: { edgeName: "learningRelCommunities", direction: "inverse", gen: false },
    relLibraries: { edgeName: "communityRelLibraries", direction: "direct", gen: true },
    relPlangs: { edgeName: "communityRelPlangs", direction: "direct", gen: true },
    relPosts: { edgeName: "postRelCommunities", direction: "inverse", gen: false },
    relSubsystems: { edgeName: "communityRelSubsystems", direction: "direct", gen: true },
    relTags: { edgeName: "tagRelCommunities", direction: "inverse", gen: true },
    relTools: { edgeName: "communityRelTools", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VCommunityBase.vertexKind;
  override readonly vertexDesc = VCommunityBase.vertexDesc;
  override readonly vertexName = VCommunityBase.vertexName;
  readonly relConfig = VCommunityBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VCommunityBase.relConfig) as VCommunityRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Apps `(this:VCommunity)-[communityRelApps]->VApp`. Inverse: {@link VApp.relCommunities}. */
  get relApps() {
    return new RelFrom(this as unknown as VC.VCommunity, this.graph.edges.communityRelApps);
  }

  /** Learning Resources `VLearning-[learningRelCommunities]->(this:VCommunity)`. Inverse: {@link VLearning.relCommunities}. */
  get relLearning() {
    return new RelTo(this as unknown as VC.VCommunity, this.graph.edges.learningRelCommunities);
  }

  /** Libraries `(this:VCommunity)-[communityRelLibraries]->VLibrary`. Inverse: {@link VLibrary.relCommunities}. */
  get relLibraries() {
    return new RelFrom(this as unknown as VC.VCommunity, this.graph.edges.communityRelLibraries);
  }

  /** Plangs `(this:VCommunity)-[communityRelPlangs]->VPlang`. Inverse: {@link VPlang.relCommunities}. */
  get relPlangs() {
    return new RelFrom(this as unknown as VC.VCommunity, this.graph.edges.communityRelPlangs);
  }

  /** Posts `VPost-[postRelCommunities]->(this:VCommunity)`. Inverse: {@link VPost.relCommunities}. */
  get relPosts() {
    return new RelTo(this as unknown as VC.VCommunity, this.graph.edges.postRelCommunities);
  }

  /** Subsystems `(this:VCommunity)-[communityRelSubsystems]->VSubsystem`. Inverse: {@link VSubsystem.relCommunities}. */
  get relSubsystems() {
    return new RelFrom(this as unknown as VC.VCommunity, this.graph.edges.communityRelSubsystems);
  }

  /** Tags `VTag-[tagRelCommunities]->(this:VCommunity)`. Inverse: {@link VTag.relCommunities}. */
  get relTags() {
    return new RelTo(this as unknown as VC.VCommunity, this.graph.edges.tagRelCommunities);
  }

  /** Tools `(this:VCommunity)-[communityRelTools]->VTool`. Inverse: {@link VTool.relCommunities}. */
  get relTools() {
    return new RelFrom(this as unknown as VC.VCommunity, this.graph.edges.communityRelTools);
  }
}

/** Type of the key of an instance of {@link VLearning}. */
export type VLearningKey = `learn+${string}`;

export type VLearningRelName = keyof (typeof VLearningBase)["relConfig"];

/** Learning Resource */
export abstract class VLearningBase extends PlangsVertex<"learn", VD.VLearningData> {
  static readonly vertexKind = "learn" as const;
  static readonly vertexName = "learning" as const;
  static readonly vertexDesc = "Learning Resource";
  static readonly relConfig = {
    relApps: { edgeName: "learningRelApps", direction: "direct", gen: true },
    relCommunities: { edgeName: "learningRelCommunities", direction: "direct", gen: true },
    relLibraries: { edgeName: "learningRelLibraries", direction: "direct", gen: true },
    relPlangs: { edgeName: "learningRelPlangs", direction: "direct", gen: true },
    relPosts: { edgeName: "postRelLearning", direction: "inverse", gen: false },
    relSubsystems: { edgeName: "learningRelSubsystems", direction: "direct", gen: true },
    relTags: { edgeName: "tagRelLearning", direction: "inverse", gen: true },
    relTools: { edgeName: "learningRelTools", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VLearningBase.vertexKind;
  override readonly vertexDesc = VLearningBase.vertexDesc;
  override readonly vertexName = VLearningBase.vertexName;
  readonly relConfig = VLearningBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VLearningBase.relConfig) as VLearningRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Apps `(this:VLearning)-[learningRelApps]->VApp`. Inverse: {@link VApp.relLearning}. */
  get relApps() {
    return new RelFrom(this as unknown as VC.VLearning, this.graph.edges.learningRelApps);
  }

  /** Communities `(this:VLearning)-[learningRelCommunities]->VCommunity`. Inverse: {@link VCommunity.relLearning}. */
  get relCommunities() {
    return new RelFrom(this as unknown as VC.VLearning, this.graph.edges.learningRelCommunities);
  }

  /** Libraries `(this:VLearning)-[learningRelLibraries]->VLibrary`. Inverse: {@link VLibrary.relLearning}. */
  get relLibraries() {
    return new RelFrom(this as unknown as VC.VLearning, this.graph.edges.learningRelLibraries);
  }

  /** Plangs `(this:VLearning)-[learningRelPlangs]->VPlang`. Inverse: {@link VPlang.relLearning}. */
  get relPlangs() {
    return new RelFrom(this as unknown as VC.VLearning, this.graph.edges.learningRelPlangs);
  }

  /** Posts `VPost-[postRelLearning]->(this:VLearning)`. Inverse: {@link VPost.relLearning}. */
  get relPosts() {
    return new RelTo(this as unknown as VC.VLearning, this.graph.edges.postRelLearning);
  }

  /** Subsystems `(this:VLearning)-[learningRelSubsystems]->VSubsystem`. Inverse: {@link VSubsystem.relLearning}. */
  get relSubsystems() {
    return new RelFrom(this as unknown as VC.VLearning, this.graph.edges.learningRelSubsystems);
  }

  /** Tags `VTag-[tagRelLearning]->(this:VLearning)`. Inverse: {@link VTag.relLearning}. */
  get relTags() {
    return new RelTo(this as unknown as VC.VLearning, this.graph.edges.tagRelLearning);
  }

  /** Tools `(this:VLearning)-[learningRelTools]->VTool`. Inverse: {@link VTool.relLearning}. */
  get relTools() {
    return new RelFrom(this as unknown as VC.VLearning, this.graph.edges.learningRelTools);
  }
}

/** Type of the key of an instance of {@link VLibrary}. */
export type VLibraryKey = `lib+${string}`;

export type VLibraryRelName = keyof (typeof VLibraryBase)["relConfig"];

/** Software Library */
export abstract class VLibraryBase extends PlangsVertex<"lib", VD.VLibraryData> {
  static readonly vertexKind = "lib" as const;
  static readonly vertexName = "library" as const;
  static readonly vertexDesc = "Software Library";
  static readonly relConfig = {
    relCommunities: { edgeName: "communityRelLibraries", direction: "inverse", gen: false },
    relLearning: { edgeName: "learningRelLibraries", direction: "inverse", gen: false },
    relLicenses: { edgeName: "licenseRelLibraries", direction: "inverse", gen: true },
    relPlangs: { edgeName: "libraryRelPlangs", direction: "direct", gen: true },
    relPlatforms: { edgeName: "libraryRelPlatforms", direction: "direct", gen: true },
    relPosts: { edgeName: "postRelLibraries", direction: "inverse", gen: false },
    relTags: { edgeName: "tagRelLibraries", direction: "inverse", gen: true },
    relWrittenWith: { edgeName: "libraryRelWrittenWith", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VLibraryBase.vertexKind;
  override readonly vertexDesc = VLibraryBase.vertexDesc;
  override readonly vertexName = VLibraryBase.vertexName;
  readonly relConfig = VLibraryBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VLibraryBase.relConfig) as VLibraryRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Communities `VCommunity-[communityRelLibraries]->(this:VLibrary)`. Inverse: {@link VCommunity.relLibraries}. */
  get relCommunities() {
    return new RelTo(this as unknown as VC.VLibrary, this.graph.edges.communityRelLibraries);
  }

  /** Learning Resources `VLearning-[learningRelLibraries]->(this:VLibrary)`. Inverse: {@link VLearning.relLibraries}. */
  get relLearning() {
    return new RelTo(this as unknown as VC.VLibrary, this.graph.edges.learningRelLibraries);
  }

  /** Licenses `VLicense-[licenseRelLibraries]->(this:VLibrary)`. Inverse: {@link VLicense.relLibraries}. */
  get relLicenses() {
    return new RelTo(this as unknown as VC.VLibrary, this.graph.edges.licenseRelLibraries);
  }

  /** Plangs `(this:VLibrary)-[libraryRelPlangs]->VPlang`. Inverse: {@link VPlang.relLibraries}. */
  get relPlangs() {
    return new RelFrom(this as unknown as VC.VLibrary, this.graph.edges.libraryRelPlangs);
  }

  /** Platforms `(this:VLibrary)-[libraryRelPlatforms]->VPlatform`. Inverse: {@link VPlatform.relLibraries}. */
  get relPlatforms() {
    return new RelFrom(this as unknown as VC.VLibrary, this.graph.edges.libraryRelPlatforms);
  }

  /** Posts `VPost-[postRelLibraries]->(this:VLibrary)`. Inverse: {@link VPost.relLibraries}. */
  get relPosts() {
    return new RelTo(this as unknown as VC.VLibrary, this.graph.edges.postRelLibraries);
  }

  /** Tags `VTag-[tagRelLibraries]->(this:VLibrary)`. Inverse: {@link VTag.relLibraries}. */
  get relTags() {
    return new RelTo(this as unknown as VC.VLibrary, this.graph.edges.tagRelLibraries);
  }

  /** Written With `(this:VLibrary)-[libraryRelWrittenWith]->VPlang`. Inverse: {@link VPlang.relUsedInLibrary}. */
  get relWrittenWith() {
    return new RelFrom(this as unknown as VC.VLibrary, this.graph.edges.libraryRelWrittenWith);
  }
}

/** Type of the key of an instance of {@link VLicense}. */
export type VLicenseKey = `lic+${string}`;

export type VLicenseRelName = keyof (typeof VLicenseBase)["relConfig"];

/** Software License */
export abstract class VLicenseBase extends PlangsVertex<"lic", VD.VLicenseData> {
  static readonly vertexKind = "lic" as const;
  static readonly vertexName = "license" as const;
  static readonly vertexDesc = "Software License";
  static readonly relConfig = {
    relApps: { edgeName: "licenseRelApps", direction: "direct", gen: false },
    relLibraries: { edgeName: "licenseRelLibraries", direction: "direct", gen: false },
    relPlangs: { edgeName: "licenseRelPlangs", direction: "direct", gen: false },
    relSubsystems: { edgeName: "licenseRelSubsystems", direction: "direct", gen: false },
    relTools: { edgeName: "licenseRelTools", direction: "direct", gen: false },
  } as const;

  override readonly vertexKind = VLicenseBase.vertexKind;
  override readonly vertexDesc = VLicenseBase.vertexDesc;
  override readonly vertexName = VLicenseBase.vertexName;
  readonly relConfig = VLicenseBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VLicenseBase.relConfig) as VLicenseRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Apps `(this:VLicense)-[licenseRelApps]->VApp`. Inverse: {@link VApp.relLicenses}. */
  get relApps() {
    return new RelFrom(this as unknown as VC.VLicense, this.graph.edges.licenseRelApps);
  }

  /** Libraries `(this:VLicense)-[licenseRelLibraries]->VLibrary`. Inverse: {@link VLibrary.relLicenses}. */
  get relLibraries() {
    return new RelFrom(this as unknown as VC.VLicense, this.graph.edges.licenseRelLibraries);
  }

  /** Plangs `(this:VLicense)-[licenseRelPlangs]->VPlang`. Inverse: {@link VPlang.relLicenses}. */
  get relPlangs() {
    return new RelFrom(this as unknown as VC.VLicense, this.graph.edges.licenseRelPlangs);
  }

  /** Subsystems `(this:VLicense)-[licenseRelSubsystems]->VSubsystem`. Inverse: {@link VSubsystem.relLicenses}. */
  get relSubsystems() {
    return new RelFrom(this as unknown as VC.VLicense, this.graph.edges.licenseRelSubsystems);
  }

  /** Tools `(this:VLicense)-[licenseRelTools]->VTool`. Inverse: {@link VTool.relLicenses}. */
  get relTools() {
    return new RelFrom(this as unknown as VC.VLicense, this.graph.edges.licenseRelTools);
  }
}

/** Type of the key of an instance of {@link VParadigm}. */
export type VParadigmKey = `para+${string}`;

export type VParadigmRelName = keyof (typeof VParadigmBase)["relConfig"];

/** Programming Language Paradigm */
export abstract class VParadigmBase extends PlangsVertex<"para", VD.VParadigmData> {
  static readonly vertexKind = "para" as const;
  static readonly vertexName = "paradigm" as const;
  static readonly vertexDesc = "Programming Language Paradigm";
  static readonly relConfig = { relPlangs: { edgeName: "plangRelParadigms", direction: "inverse", gen: false } } as const;

  override readonly vertexKind = VParadigmBase.vertexKind;
  override readonly vertexDesc = VParadigmBase.vertexDesc;
  override readonly vertexName = VParadigmBase.vertexName;
  readonly relConfig = VParadigmBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VParadigmBase.relConfig) as VParadigmRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Plangs `VPlang-[plangRelParadigms]->(this:VParadigm)`. Inverse: {@link VPlang.relParadigms}. */
  get relPlangs() {
    return new RelTo(this as unknown as VC.VParadigm, this.graph.edges.plangRelParadigms);
  }
}

/** Type of the key of an instance of {@link VPlang}. */
export type VPlangKey = `pl+${string}`;

export type VPlangRelName = keyof (typeof VPlangBase)["relConfig"];

/** Programming Language */
export abstract class VPlangBase extends PlangsVertex<"pl", VD.VPlangData> {
  static readonly vertexKind = "pl" as const;
  static readonly vertexName = "plang" as const;
  static readonly vertexDesc = "Programming Language";
  static readonly relConfig = {
    relApps: { edgeName: "appRelWrittenWith", direction: "inverse", gen: false },
    relBundles: { edgeName: "bundleRelPlangs", direction: "inverse", gen: false },
    relCommunities: { edgeName: "communityRelPlangs", direction: "inverse", gen: false },
    relCompilesTo: { edgeName: "plangRelCompilesTo", direction: "direct", gen: true },
    relDialectOf: { edgeName: "plangRelDialectOf", direction: "direct", gen: true },
    relDialects: { edgeName: "plangRelDialectOf", direction: "inverse", gen: false },
    relImplementedBy: { edgeName: "plangRelImplements", direction: "inverse", gen: false },
    relImplements: { edgeName: "plangRelImplements", direction: "direct", gen: true },
    relInfluenced: { edgeName: "plangRelInfluencedBy", direction: "inverse", gen: false },
    relInfluencedBy: { edgeName: "plangRelInfluencedBy", direction: "direct", gen: true },
    relLearning: { edgeName: "learningRelPlangs", direction: "inverse", gen: false },
    relLibraries: { edgeName: "libraryRelPlangs", direction: "inverse", gen: false },
    relLicenses: { edgeName: "licenseRelPlangs", direction: "inverse", gen: true },
    relParadigms: { edgeName: "plangRelParadigms", direction: "direct", gen: true },
    relPlatforms: { edgeName: "plangRelPlatforms", direction: "direct", gen: true },
    relPosts: { edgeName: "postRelPlangs", direction: "inverse", gen: false },
    relSubsystems: { edgeName: "subsystemRelWrittenWith", direction: "inverse", gen: false },
    relTags: { edgeName: "tagRelPlangs", direction: "inverse", gen: true },
    relTargetOf: { edgeName: "plangRelCompilesTo", direction: "inverse", gen: false },
    relTools: { edgeName: "plangRelTools", direction: "direct", gen: false },
    relToolsUsing: { edgeName: "toolRelWrittenWith", direction: "inverse", gen: false },
    relTypeSystems: { edgeName: "plangRelTypeSystems", direction: "direct", gen: true },
    relUsedInLibrary: { edgeName: "libraryRelWrittenWith", direction: "inverse", gen: false },
    relUsedToWrite: { edgeName: "plangRelWrittenWith", direction: "inverse", gen: false },
    relWrittenWith: { edgeName: "plangRelWrittenWith", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VPlangBase.vertexKind;
  override readonly vertexDesc = VPlangBase.vertexDesc;
  override readonly vertexName = VPlangBase.vertexName;
  readonly relConfig = VPlangBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VPlangBase.relConfig) as VPlangRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Apps `VApp-[appRelWrittenWith]->(this:VPlang)`. Inverse: {@link VApp.relWrittenWith}. */
  get relApps() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.appRelWrittenWith);
  }

  /** Bundles `VBundle-[bundleRelPlangs]->(this:VPlang)`. Inverse: {@link VBundle.relPlangs}. */
  get relBundles() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.bundleRelPlangs);
  }

  /** Communities `VCommunity-[communityRelPlangs]->(this:VPlang)`. Inverse: {@link VCommunity.relPlangs}. */
  get relCommunities() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.communityRelPlangs);
  }

  /** Transpiling Targets `(this:VPlang)-[plangRelCompilesTo]->VPlang`. Inverse: {@link VPlang.relTargetOf}. */
  get relCompilesTo() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelCompilesTo);
  }

  /** Dialect of `(this:VPlang)-[plangRelDialectOf]->VPlang`. Inverse: {@link VPlang.relDialects}. */
  get relDialectOf() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelDialectOf);
  }

  /** Dialects `VPlang-[plangRelDialectOf]->(this:VPlang)`. Inverse: {@link VPlang.relDialectOf}. */
  get relDialects() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.plangRelDialectOf);
  }

  /** Implemented By `VPlang-[plangRelImplements]->(this:VPlang)`. Inverse: {@link VPlang.relImplements}. */
  get relImplementedBy() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.plangRelImplements);
  }

  /** Implements `(this:VPlang)-[plangRelImplements]->VPlang`. Inverse: {@link VPlang.relImplementedBy}. */
  get relImplements() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelImplements);
  }

  /** Influenced `VPlang-[plangRelInfluencedBy]->(this:VPlang)`. Inverse: {@link VPlang.relInfluencedBy}. */
  get relInfluenced() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.plangRelInfluencedBy);
  }

  /** Influenced By `(this:VPlang)-[plangRelInfluencedBy]->VPlang`. Inverse: {@link VPlang.relInfluenced}. */
  get relInfluencedBy() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelInfluencedBy);
  }

  /** Learning Resources `VLearning-[learningRelPlangs]->(this:VPlang)`. Inverse: {@link VLearning.relPlangs}. */
  get relLearning() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.learningRelPlangs);
  }

  /** Libraries `VLibrary-[libraryRelPlangs]->(this:VPlang)`. Inverse: {@link VLibrary.relPlangs}. */
  get relLibraries() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.libraryRelPlangs);
  }

  /** Licenses `VLicense-[licenseRelPlangs]->(this:VPlang)`. Inverse: {@link VLicense.relPlangs}. */
  get relLicenses() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.licenseRelPlangs);
  }

  /** Paradigms `(this:VPlang)-[plangRelParadigms]->VParadigm`. Inverse: {@link VParadigm.relPlangs}. */
  get relParadigms() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelParadigms);
  }

  /** Platforms `(this:VPlang)-[plangRelPlatforms]->VPlatform`. Inverse: {@link VPlatform.relPlangs}. */
  get relPlatforms() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelPlatforms);
  }

  /** Posts `VPost-[postRelPlangs]->(this:VPlang)`. Inverse: {@link VPost.relPlangs}. */
  get relPosts() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.postRelPlangs);
  }

  /** Subsystems `VSubsystem-[subsystemRelWrittenWith]->(this:VPlang)`. Inverse: {@link VSubsystem.relWrittenWith}. */
  get relSubsystems() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.subsystemRelWrittenWith);
  }

  /** Tags `VTag-[tagRelPlangs]->(this:VPlang)`. Inverse: {@link VTag.relPlangs}. */
  get relTags() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.tagRelPlangs);
  }

  /** Source Plangs `VPlang-[plangRelCompilesTo]->(this:VPlang)`. Inverse: {@link VPlang.relCompilesTo}. */
  get relTargetOf() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.plangRelCompilesTo);
  }

  /** Tools `(this:VPlang)-[plangRelTools]->VTool`. Inverse: {@link VTool.relPlangs}. */
  get relTools() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelTools);
  }

  /** Tool Plang `VTool-[toolRelWrittenWith]->(this:VPlang)`. Inverse: {@link VTool.relWrittenWith}. */
  get relToolsUsing() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.toolRelWrittenWith);
  }

  /** Type Systems `(this:VPlang)-[plangRelTypeSystems]->VTypeSystem`. Inverse: {@link VTypeSystem.relPlangs}. */
  get relTypeSystems() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelTypeSystems);
  }

  /** Used for Libraries `VLibrary-[libraryRelWrittenWith]->(this:VPlang)`. Inverse: {@link VLibrary.relWrittenWith}. */
  get relUsedInLibrary() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.libraryRelWrittenWith);
  }

  /** Used to Write `VPlang-[plangRelWrittenWith]->(this:VPlang)`. Inverse: {@link VPlang.relWrittenWith}. */
  get relUsedToWrite() {
    return new RelTo(this as unknown as VC.VPlang, this.graph.edges.plangRelWrittenWith);
  }

  /** Written With `(this:VPlang)-[plangRelWrittenWith]->VPlang`. Inverse: {@link VPlang.relUsedToWrite}. */
  get relWrittenWith() {
    return new RelFrom(this as unknown as VC.VPlang, this.graph.edges.plangRelWrittenWith);
  }
}

/** Type of the key of an instance of {@link VPlatform}. */
export type VPlatformKey = `plat+${string}`;

export type VPlatformRelName = keyof (typeof VPlatformBase)["relConfig"];

/** Platform where Software runs */
export abstract class VPlatformBase extends PlangsVertex<"plat", VD.VPlatformData> {
  static readonly vertexKind = "plat" as const;
  static readonly vertexName = "platform" as const;
  static readonly vertexDesc = "Platform where Software runs";
  static readonly relConfig = {
    relApps: { edgeName: "appRelPlatforms", direction: "inverse", gen: false },
    relLibraries: { edgeName: "libraryRelPlatforms", direction: "inverse", gen: false },
    relPlangs: { edgeName: "plangRelPlatforms", direction: "inverse", gen: false },
    relSubsystems: { edgeName: "subsystemRelPlatforms", direction: "inverse", gen: false },
    relTools: { edgeName: "toolRelPlatforms", direction: "inverse", gen: false },
  } as const;

  override readonly vertexKind = VPlatformBase.vertexKind;
  override readonly vertexDesc = VPlatformBase.vertexDesc;
  override readonly vertexName = VPlatformBase.vertexName;
  readonly relConfig = VPlatformBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VPlatformBase.relConfig) as VPlatformRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Apps `VApp-[appRelPlatforms]->(this:VPlatform)`. Inverse: {@link VApp.relPlatforms}. */
  get relApps() {
    return new RelTo(this as unknown as VC.VPlatform, this.graph.edges.appRelPlatforms);
  }

  /** Libraries `VLibrary-[libraryRelPlatforms]->(this:VPlatform)`. Inverse: {@link VLibrary.relPlatforms}. */
  get relLibraries() {
    return new RelTo(this as unknown as VC.VPlatform, this.graph.edges.libraryRelPlatforms);
  }

  /** Plangs `VPlang-[plangRelPlatforms]->(this:VPlatform)`. Inverse: {@link VPlang.relPlatforms}. */
  get relPlangs() {
    return new RelTo(this as unknown as VC.VPlatform, this.graph.edges.plangRelPlatforms);
  }

  /** Subsystems `VSubsystem-[subsystemRelPlatforms]->(this:VPlatform)`. Inverse: {@link VSubsystem.relPlatforms}. */
  get relSubsystems() {
    return new RelTo(this as unknown as VC.VPlatform, this.graph.edges.subsystemRelPlatforms);
  }

  /** Tools `VTool-[toolRelPlatforms]->(this:VPlatform)`. Inverse: {@link VTool.relPlatforms}. */
  get relTools() {
    return new RelTo(this as unknown as VC.VPlatform, this.graph.edges.toolRelPlatforms);
  }
}

/** Type of the key of an instance of {@link VPost}. */
export type VPostKey = `post+${string}`;

export type VPostRelName = keyof (typeof VPostBase)["relConfig"];

/** Blog Post */
export abstract class VPostBase extends PlangsVertex<"post", VD.VPostData> {
  static readonly vertexKind = "post" as const;
  static readonly vertexName = "post" as const;
  static readonly vertexDesc = "Blog Post";
  static readonly relConfig = {
    relApps: { edgeName: "postRelApps", direction: "direct", gen: false },
    relCommunities: { edgeName: "postRelCommunities", direction: "direct", gen: false },
    relLearning: { edgeName: "postRelLearning", direction: "direct", gen: false },
    relLibraries: { edgeName: "postRelLibraries", direction: "direct", gen: false },
    relPlangs: { edgeName: "postRelPlangs", direction: "direct", gen: false },
    relSubsystems: { edgeName: "postRelSubsystems", direction: "direct", gen: false },
    relTools: { edgeName: "postRelTools", direction: "direct", gen: false },
  } as const;

  override readonly vertexKind = VPostBase.vertexKind;
  override readonly vertexDesc = VPostBase.vertexDesc;
  override readonly vertexName = VPostBase.vertexName;
  readonly relConfig = VPostBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VPostBase.relConfig) as VPostRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Apps `(this:VPost)-[postRelApps]->VApp`. Inverse: {@link VApp.relPosts}. */
  get relApps() {
    return new RelFrom(this as unknown as VC.VPost, this.graph.edges.postRelApps);
  }

  /** Communities `(this:VPost)-[postRelCommunities]->VCommunity`. Inverse: {@link VCommunity.relPosts}. */
  get relCommunities() {
    return new RelFrom(this as unknown as VC.VPost, this.graph.edges.postRelCommunities);
  }

  /** Learning Resources `(this:VPost)-[postRelLearning]->VLearning`. Inverse: {@link VLearning.relPosts}. */
  get relLearning() {
    return new RelFrom(this as unknown as VC.VPost, this.graph.edges.postRelLearning);
  }

  /** Libraries `(this:VPost)-[postRelLibraries]->VLibrary`. Inverse: {@link VLibrary.relPosts}. */
  get relLibraries() {
    return new RelFrom(this as unknown as VC.VPost, this.graph.edges.postRelLibraries);
  }

  /** Plangs `(this:VPost)-[postRelPlangs]->VPlang`. Inverse: {@link VPlang.relPosts}. */
  get relPlangs() {
    return new RelFrom(this as unknown as VC.VPost, this.graph.edges.postRelPlangs);
  }

  /** Subsystems `(this:VPost)-[postRelSubsystems]->VSubsystem`. Inverse: {@link VSubsystem.relPosts}. */
  get relSubsystems() {
    return new RelFrom(this as unknown as VC.VPost, this.graph.edges.postRelSubsystems);
  }

  /** Tools `(this:VPost)-[postRelTools]->VTool`. Inverse: {@link VTool.relPosts}. */
  get relTools() {
    return new RelFrom(this as unknown as VC.VPost, this.graph.edges.postRelTools);
  }
}

/** Type of the key of an instance of {@link VSubsystem}. */
export type VSubsystemKey = `sys+${string}`;

export type VSubsystemRelName = keyof (typeof VSubsystemBase)["relConfig"];

/** Subsystem */
export abstract class VSubsystemBase extends PlangsVertex<"sys", VD.VSubsystemData> {
  static readonly vertexKind = "sys" as const;
  static readonly vertexName = "subsystem" as const;
  static readonly vertexDesc = "Subsystem";
  static readonly relConfig = {
    relCommunities: { edgeName: "communityRelSubsystems", direction: "inverse", gen: false },
    relLearning: { edgeName: "learningRelSubsystems", direction: "inverse", gen: false },
    relLicenses: { edgeName: "licenseRelSubsystems", direction: "inverse", gen: true },
    relPlatforms: { edgeName: "subsystemRelPlatforms", direction: "direct", gen: true },
    relPosts: { edgeName: "postRelSubsystems", direction: "inverse", gen: false },
    relTags: { edgeName: "tagRelSubsystems", direction: "inverse", gen: true },
    relWrittenWith: { edgeName: "subsystemRelWrittenWith", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VSubsystemBase.vertexKind;
  override readonly vertexDesc = VSubsystemBase.vertexDesc;
  override readonly vertexName = VSubsystemBase.vertexName;
  readonly relConfig = VSubsystemBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VSubsystemBase.relConfig) as VSubsystemRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Communities `VCommunity-[communityRelSubsystems]->(this:VSubsystem)`. Inverse: {@link VCommunity.relSubsystems}. */
  get relCommunities() {
    return new RelTo(this as unknown as VC.VSubsystem, this.graph.edges.communityRelSubsystems);
  }

  /** Learning Resources `VLearning-[learningRelSubsystems]->(this:VSubsystem)`. Inverse: {@link VLearning.relSubsystems}. */
  get relLearning() {
    return new RelTo(this as unknown as VC.VSubsystem, this.graph.edges.learningRelSubsystems);
  }

  /** Licenses `VLicense-[licenseRelSubsystems]->(this:VSubsystem)`. Inverse: {@link VLicense.relSubsystems}. */
  get relLicenses() {
    return new RelTo(this as unknown as VC.VSubsystem, this.graph.edges.licenseRelSubsystems);
  }

  /** Platforms `(this:VSubsystem)-[subsystemRelPlatforms]->VPlatform`. Inverse: {@link VPlatform.relSubsystems}. */
  get relPlatforms() {
    return new RelFrom(this as unknown as VC.VSubsystem, this.graph.edges.subsystemRelPlatforms);
  }

  /** Posts `VPost-[postRelSubsystems]->(this:VSubsystem)`. Inverse: {@link VPost.relSubsystems}. */
  get relPosts() {
    return new RelTo(this as unknown as VC.VSubsystem, this.graph.edges.postRelSubsystems);
  }

  /** Tags `VTag-[tagRelSubsystems]->(this:VSubsystem)`. Inverse: {@link VTag.relSubsystems}. */
  get relTags() {
    return new RelTo(this as unknown as VC.VSubsystem, this.graph.edges.tagRelSubsystems);
  }

  /** Plangs `(this:VSubsystem)-[subsystemRelWrittenWith]->VPlang`. Inverse: {@link VPlang.relSubsystems}. */
  get relWrittenWith() {
    return new RelFrom(this as unknown as VC.VSubsystem, this.graph.edges.subsystemRelWrittenWith);
  }
}

/** Type of the key of an instance of {@link VTag}. */
export type VTagKey = `tag+${string}`;

export type VTagRelName = keyof (typeof VTagBase)["relConfig"];

/** Tag */
export abstract class VTagBase extends PlangsVertex<"tag", VD.VTagData> {
  static readonly vertexKind = "tag" as const;
  static readonly vertexName = "tag" as const;
  static readonly vertexDesc = "Tag";
  static readonly relConfig = {
    relApps: { edgeName: "tagRelApps", direction: "direct", gen: false },
    relCommunities: { edgeName: "tagRelCommunities", direction: "direct", gen: false },
    relLearning: { edgeName: "tagRelLearning", direction: "direct", gen: false },
    relLibraries: { edgeName: "tagRelLibraries", direction: "direct", gen: false },
    relPlangs: { edgeName: "tagRelPlangs", direction: "direct", gen: false },
    relSubsystems: { edgeName: "tagRelSubsystems", direction: "direct", gen: false },
    relTools: { edgeName: "tagRelTools", direction: "direct", gen: false },
  } as const;

  override readonly vertexKind = VTagBase.vertexKind;
  override readonly vertexDesc = VTagBase.vertexDesc;
  override readonly vertexName = VTagBase.vertexName;
  readonly relConfig = VTagBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VTagBase.relConfig) as VTagRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Apps tagged `(this:VTag)-[tagRelApps]->VApp`. Inverse: {@link VApp.relTags}. */
  get relApps() {
    return new RelFrom(this as unknown as VC.VTag, this.graph.edges.tagRelApps);
  }

  /** Communities `(this:VTag)-[tagRelCommunities]->VCommunity`. Inverse: {@link VCommunity.relTags}. */
  get relCommunities() {
    return new RelFrom(this as unknown as VC.VTag, this.graph.edges.tagRelCommunities);
  }

  /** Learning Resources `(this:VTag)-[tagRelLearning]->VLearning`. Inverse: {@link VLearning.relTags}. */
  get relLearning() {
    return new RelFrom(this as unknown as VC.VTag, this.graph.edges.tagRelLearning);
  }

  /** Libraries `(this:VTag)-[tagRelLibraries]->VLibrary`. Inverse: {@link VLibrary.relTags}. */
  get relLibraries() {
    return new RelFrom(this as unknown as VC.VTag, this.graph.edges.tagRelLibraries);
  }

  /** Plangs `(this:VTag)-[tagRelPlangs]->VPlang`. Inverse: {@link VPlang.relTags}. */
  get relPlangs() {
    return new RelFrom(this as unknown as VC.VTag, this.graph.edges.tagRelPlangs);
  }

  /** Subsystems `(this:VTag)-[tagRelSubsystems]->VSubsystem`. Inverse: {@link VSubsystem.relTags}. */
  get relSubsystems() {
    return new RelFrom(this as unknown as VC.VTag, this.graph.edges.tagRelSubsystems);
  }

  /** Tools `(this:VTag)-[tagRelTools]->VTool`. Inverse: {@link VTool.relTags}. */
  get relTools() {
    return new RelFrom(this as unknown as VC.VTag, this.graph.edges.tagRelTools);
  }
}

/** Type of the key of an instance of {@link VTool}. */
export type VToolKey = `tool+${string}`;

export type VToolRelName = keyof (typeof VToolBase)["relConfig"];

/** Programming Tool */
export abstract class VToolBase extends PlangsVertex<"tool", VD.VToolData> {
  static readonly vertexKind = "tool" as const;
  static readonly vertexName = "tool" as const;
  static readonly vertexDesc = "Programming Tool";
  static readonly relConfig = {
    relBundles: { edgeName: "bundleRelTools", direction: "inverse", gen: false },
    relCommunities: { edgeName: "communityRelTools", direction: "inverse", gen: false },
    relLearning: { edgeName: "learningRelTools", direction: "inverse", gen: false },
    relLicenses: { edgeName: "licenseRelTools", direction: "inverse", gen: true },
    relPlangs: { edgeName: "plangRelTools", direction: "inverse", gen: true },
    relPlatforms: { edgeName: "toolRelPlatforms", direction: "direct", gen: true },
    relPosts: { edgeName: "postRelTools", direction: "inverse", gen: false },
    relTags: { edgeName: "tagRelTools", direction: "inverse", gen: true },
    relWrittenWith: { edgeName: "toolRelWrittenWith", direction: "direct", gen: true },
  } as const;

  override readonly vertexKind = VToolBase.vertexKind;
  override readonly vertexDesc = VToolBase.vertexDesc;
  override readonly vertexName = VToolBase.vertexName;
  readonly relConfig = VToolBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VToolBase.relConfig) as VToolRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Bundles `VBundle-[bundleRelTools]->(this:VTool)`. Inverse: {@link VBundle.relTools}. */
  get relBundles() {
    return new RelTo(this as unknown as VC.VTool, this.graph.edges.bundleRelTools);
  }

  /** Communities `VCommunity-[communityRelTools]->(this:VTool)`. Inverse: {@link VCommunity.relTools}. */
  get relCommunities() {
    return new RelTo(this as unknown as VC.VTool, this.graph.edges.communityRelTools);
  }

  /** Learning Resources `VLearning-[learningRelTools]->(this:VTool)`. Inverse: {@link VLearning.relTools}. */
  get relLearning() {
    return new RelTo(this as unknown as VC.VTool, this.graph.edges.learningRelTools);
  }

  /** Licenses `VLicense-[licenseRelTools]->(this:VTool)`. Inverse: {@link VLicense.relTools}. */
  get relLicenses() {
    return new RelTo(this as unknown as VC.VTool, this.graph.edges.licenseRelTools);
  }

  /** Plangs `VPlang-[plangRelTools]->(this:VTool)`. Inverse: {@link VPlang.relTools}. */
  get relPlangs() {
    return new RelTo(this as unknown as VC.VTool, this.graph.edges.plangRelTools);
  }

  /** Platforms `(this:VTool)-[toolRelPlatforms]->VPlatform`. Inverse: {@link VPlatform.relTools}. */
  get relPlatforms() {
    return new RelFrom(this as unknown as VC.VTool, this.graph.edges.toolRelPlatforms);
  }

  /** Posts `VPost-[postRelTools]->(this:VTool)`. Inverse: {@link VPost.relTools}. */
  get relPosts() {
    return new RelTo(this as unknown as VC.VTool, this.graph.edges.postRelTools);
  }

  /** Tags `VTag-[tagRelTools]->(this:VTool)`. Inverse: {@link VTag.relTools}. */
  get relTags() {
    return new RelTo(this as unknown as VC.VTool, this.graph.edges.tagRelTools);
  }

  /** Implemented With `(this:VTool)-[toolRelWrittenWith]->VPlang`. Inverse: {@link VPlang.relToolsUsing}. */
  get relWrittenWith() {
    return new RelFrom(this as unknown as VC.VTool, this.graph.edges.toolRelWrittenWith);
  }
}

/** Type of the key of an instance of {@link VTypeSystem}. */
export type VTypeSystemKey = `tsys+${string}`;

export type VTypeSystemRelName = keyof (typeof VTypeSystemBase)["relConfig"];

/** Type System */
export abstract class VTypeSystemBase extends PlangsVertex<"tsys", VD.VTypeSystemData> {
  static readonly vertexKind = "tsys" as const;
  static readonly vertexName = "typeSystem" as const;
  static readonly vertexDesc = "Type System";
  static readonly relConfig = { relPlangs: { edgeName: "plangRelTypeSystems", direction: "inverse", gen: false } } as const;

  override readonly vertexKind = VTypeSystemBase.vertexKind;
  override readonly vertexDesc = VTypeSystemBase.vertexDesc;
  override readonly vertexName = VTypeSystemBase.vertexName;
  readonly relConfig = VTypeSystemBase.relConfig;

  /** All the relations, keyed by rel name. */
  get relations() {
    return new Map((Object.keys(VTypeSystemBase.relConfig) as VTypeSystemRelName[]).map(rel => [rel, this[rel]]));
  }

  /** Plangs `VPlang-[plangRelTypeSystems]->(this:VTypeSystem)`. Inverse: {@link VPlang.relTypeSystems}. */
  get relPlangs() {
    return new RelTo(this as unknown as VC.VTypeSystem, this.graph.edges.plangRelTypeSystems);
  }
}
